/* Custom Font Import */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@500;600&family=Noto+Serif+Display:ital,wght@0,600;1,600&family=Noto+Serif:wght@700&display=swap');

html,
body,
#root,
#root > div {
  margin: 0;
  height: 100%;
}

body {
  font-family: 'Inconsolata', 'Noto Serif', 'Noto Serif Display', serif !important;
  background-color: #fcfbf7;
  color: '#080F0F';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inconsolata', monospace !important;
}

.gridContainter {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 540px) {
  .gridContainter {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 720px) {
  .gridContainter {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 960px) {
  .gridContainter {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1156px) {
  .gridContainter {
    grid-template-columns: repeat(6, 1fr);
  }
}
